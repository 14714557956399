<template>
   <div>

      <h3 id="field">Search</h3>
      <p>Type either a playlist, artist, or song title.</p>
      <div class="search mb-6">
         <input type="text"
                id="searchInput"
                placeholder="Massive Attack"
                autocomplete="off"
                ref="search"
                v-model="search"
                v-on:focus="handleClickInside"
                @input="onChange"
                @keydown.down.tab="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                @keydown.esc="clearInput"
         />
         <ul v-show="isOpen">
            <li v-for="(result, index) in results" :key="index + 'A'">
               <router-link :class="{ 'isActive': index === arrowCounter }"
                            :to="{ name: 'playlist', params: {slug: result.entry.slug, entry: result.entry} }">
                  <span class="title">{{ result.entry.title }}</span>
                  <span class="description">{{ result.details }}</span>
               </router-link>
            </li>
         </ul>
      </div>

      <div class="row">
         <div class="col-lg-8">
            <div class="tags">

               <h3 id="genres">Genres</h3>
               <TagsGroup group="genres" :tags="genres" />
               <hr>

               <h3 id="moods">Moods</h3>
               <TagsGroup group="moods" :tags="moods" />
               <hr>

               <h3 id="colors">Colors</h3>
               <TagsGroup group="colors" :tags="colors" />
               <hr>

            </div>
         </div>
         <div class="col-lg-3 offset-lg-1">
            <h3 id="time">Time of Day</h3>
            <p>Filter playlists by when it's suggested they be played.</p>
            <div class="btnStack">
               <div v-for="(btn, index) in times" :key="index + 'B'">
                  <Button :small="true"
                          :text="btn.btnText"
                          :route="{ name: 'times', params: {slug: btn.slug} }"
                  />
               </div>
            </div>

            <hr>

            <h3 id="activities">Activities</h3>
            <p>Filter playlists by what you'd like to be doing.</p>
            <TagsGroup group="activities" :tags="activities" />
         </div>
      </div>
   </div>
</template>

<script>
    import _ from 'lodash';
    import TagsGroup from '@/components/playlists/TagsGroup.vue';
    import Button from '@/components/Button.vue';

    export default {
        name: 'Search',
        components: {
            TagsGroup,
            Button
        },
        data() {
            return {
                genres: this.content.filters.genres,
                moods: this.content.filters.moods,
                colors: this.content.filters.colors,
                activities: this.content.filters.activities,
                times: this.content.filters.times,
                search: '',
                results: [],
                minCharacters: 2,
                resultsLimit: 10,
                arrowCounter: -1,
            }
        },
        props: {
            content: Object,
        },
        computed: {
            isOpen() {
                if (this.search.length > this.minCharacters && !_.isEmpty(this.results)) {
                    return true;
                }
                return false;
            },
        },
        methods: {
            onChange() {
                if (this.search.length > this.minCharacters) {
                    this.results = [];
                    /*
                        Needs to search through:
                           1. playlist.title
                           2. playlist.tracklist => track.title
                           3. playlist.tracklist => track.artist
                     */

                    let searchTerm = _.toLower(this.search);
                    let playlists = this.content.playlists;

                    for (let i = 0; i < playlists.length; i++) {

                        let entry = playlists[i];

                        // check if playlist title has term
                        let title = _.toLower(entry.title);
                        if (title.indexOf(searchTerm) > -1) {
                            this.results.push({
                                entry: entry,
                                details: entry.tracklist.length + ' songs / ' + entry.duration,
                            });
                        }

                        // check if tracklist songs' titles or artists have the term
                        for (let i = 0; i < entry.tracklist.length; i++) {
                            let song = entry.tracklist[i];
                            let exists = false;

                            // if the search term is found in the song title
                            if (_.toLower(song.title).indexOf(searchTerm) > -1) {
                                exists = true;
                            }

                            // if the search term is found in the artist name
                            if (_.toLower(song.artist).indexOf(searchTerm) > -1) {
                               exists = true;
                            }

                            if (exists) {
                                this.results.push({
                                    entry: entry,
                                    details: (i + 1) + '. "' + song.title + '" - ' + song.artist,
                                });
                            }
                        }
                    }

                    // only take the first N of results so the list isn't too big
                    this.results = _.take(this.results, this.resultsLimit);
                }
            },
            onArrowDown(event) {
                event.preventDefault();
                if (this.isOpen) {
                    this.arrowCounter = this.arrowCounter + 1;
                    if (this.arrowCounter >= this.results.length) {
                        this.arrowCounter = 0;
                    }
                }
            },
            onArrowUp() {
                if (this.isOpen) {
                    this.arrowCounter = this.arrowCounter - 1;
                    if (this.arrowCounter < 0) {
                        this.arrowCounter = this.results.length - 1;
                    }
                }
            },
            onEnter() {
                if (this.isOpen && this.arrowCounter > -1) {
                    let entry = this.results[this.arrowCounter].entry;
                    this.$router.push({name: 'playlist', params: {slug: entry.slug, entry: entry}});
                }
            },
            handleClickInside(event) {
                if (this.search.length > this.minCharacters) {
                    this.arrowCounter = -1;
                    this.onChange();
                }
            },
            handleClickOutside(event) {
                if (event.target !== this.$refs.search) {
                    this.results = [];
                    this.arrowCounter = -1;
                }
            },
            clearInput() {
                if (this.results.length) {
                    this.results = [];
                    this.arrowCounter = -1;
                    this.search = '';
                }
                else if (this.search === '') {
                    this.$refs.search.blur();
                }
                else {
                    this.search = '';
                }
            },
        },
        mounted() {
            this.$refs.search.focus();

            // add event for when user clicks outside search field (close the results box)
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
    }
</script>

<style scoped lang="scss">
   .tags .tag {
      * {
         background-color: transparent;
         border-color: $tag-bg-color;
         color: darken($tag-text-color, 30%);
      }
   }

   .btnStack {
      > div {
         margin-bottom: 1rem;
      }
   }

   $search-bg: #fff;
   $search-height: calc(1.5em + .75rem + 2px);
   .search {
      margin-bottom: 2rem;
   }

   .search {
      position: relative;

      ul {
         background-color: $search-bg;
         width: 100%;
         padding: 0;
         margin: 0;
         border: 2px solid $border-color;
         border-top: none;
         overflow: auto;
         position: absolute;
         top: $search-height;
         left: 0;
         z-index: 10;
      }

      li {
         list-style: none;

         .title {
            display: block;
            font-weight: $font-weight-bold;
            font-family: $font-family-serif;
            line-height: 1;
            color: $black;
            letter-spacing: 1.5px;
            font-size: 1rem;
         }
         .description {
            font-size: 0.6rem;
            font-weight: $font-weight-normal;
            font-family: $font-family-sans-serif;
            line-height: 1;
            color: #a5a5a5;
         }

         &:not(:last-child) a {
            border-bottom: 1px dashed $border-color;
         }

         a {
            display: block;
            padding: 10px 1rem;
            line-height: 1;

            &:active {
               background-color: #f8de7e;
               border-bottom-color: #caa726;
               .description {
                  color: #ba9e38;
               }
            }

            @include media-breakpoint-up(lg) {
               &.isActive, &:hover {
                  background-color: #f8de7e;
                  border-bottom-color: #caa726;
                  .description {
                     color: #ba9e38;
                  }
               }
            }
         }
      }
   }

   input {
      display: block;
      width: 100%;
      height: $search-height;
      margin: 0;
      padding: .5rem .75rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 2px solid $border-color;
      overflow: visible;
      line-height: 1.5;
      color: $black;
      font-size: 1rem;
      font-weight: $font-weight-normal;
      font-family: $font-family-serif;
   }

   ::placeholder {
      color: #b8b8b8;
   }
</style>