<template>
   <p id="logo"><span>Emotionalists</span></p>
</template>

<script>
    export default {
        name: 'Logo',
    }
</script>

<style lang="scss">
   @import '~@/styles/_buttonLogic';

   #logo {
      position: relative;
      left: 10px;
      display: block;
      width: 100%;
      cursor: default;
      margin: 0 0 2.5rem;
      font-family: $font-family-serif;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: $header-color !important;
      text-align: center;
      line-height: $base-line-height;

      // mobile
      font-size: 1rem;
      letter-spacing: 10px;

      span {
         background: none;
         display: block;
         vertical-align: middle;

         // mobile
         padding: 1.5rem 5px;
         border: 4px solid $black;
      }

      // making the logo look 3D
      &::before, &::after {
         content: '';
         background: $black;
         border: none;
         position: absolute;
         transition: $transition;
      }

      // LEFT SIDE of logo
      &::before {
         @include leftSide(1rem, 10px);
      }
      // BOTTOM SIDE of logo
      &::after {
         @include bottomSide(1rem, 10px);
      }


      // TABLET
      @include media-breakpoint-up(md) {
         left: 5px;
         font-size: 1rem;
         letter-spacing: 1.5rem;
      }

      // DESKTOP
      @include media-breakpoint-up(lg) {
         left: auto;
         font-size: 1.5rem;
         letter-spacing: 2rem;

         span {
            padding: 2rem 3rem;
            border-width: 5px;
         }
      }
   }

</style>