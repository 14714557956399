import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import Single from './views/Single.vue';
import _ from 'lodash';

Vue.use(Router);
Vue.use(Meta);

const LISTING_ROUTES = [
    'homepage',
    'genres',
    'moods',
    'colors',
    'activities',
    'times',
];

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'homepage',
            path: '/',
            props: {slug: 'homepage'},
            component: Single,
        },
        {
            name: 'about',
            path: '/about',
            props: {slug: 'about'},
            component: Single,
        },
        {
            name: 'search',
            path: '/search',
            props: {slug: 'search'},
            component: Single,
        },
        {
            name: 'genres',
            path: '/genre/:slug',
            props: (route) => ({
                slug: route.params.slug,
                filterBy: 'genres'
            }),
            component: () => import(/* webpackChunkName: "filter-results" */ './views/FilterResults.vue')
        },
        {
            name: 'moods',
            path: '/mood/:slug',
            props: (route) => ({
                slug: route.params.slug,
                filterBy: 'moods'
            }),
            component: () => import(/* webpackChunkName: "filter-results" */ './views/FilterResults.vue')
        },
        {
            name: 'colors',
            path: '/color/:slug',
            props: (route) => ({
                slug: route.params.slug,
                filterBy: 'colors'
            }),
            component: () => import(/* webpackChunkName: "filter-results" */ './views/FilterResults.vue')
        },
        {
            name: 'activities',
            path: '/activity/:slug',
            props: (route) => ({
                slug: route.params.slug,
                filterBy: 'activities'
            }),
            component: () => import(/* webpackChunkName: "filter-results" */ './views/FilterResults.vue')
        },
        {
            name: 'times',
            path: '/time/:slug',
            props: (route) => ({
                slug: route.params.slug,
                filterBy: 'times',
            }),
            component: () => import(/* webpackChunkName: "filter-results" */ './views/FilterResults.vue')
        },
        {
            name: 'impressum',
            path: '/impressum',
            props: {slug: 'impressum'},
            component: Single,
        },
        {
            name: 'pageNotFound',
            path: '/404',
            props: {slug: 'page-not-found'},
            component: Single,
        },
        {
            // this must be last because there is no segment before :slug, so it's a catch-all. The
            // redirect to 404 happens in Playlist.vue's setEntry() method.
            name: 'playlist',
            path: '/:slug',
            props: true,
            // route level code-splitting
            // this generates a separate chunk (playlist.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "playlist" */ './views/Playlist.vue'),
        }
    ],


    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash }
        }

        // if you're returning to the list from an entry, go to that entry's place
        // in the list
        if (from.name === 'playlist') {
            if (_.includes(LISTING_ROUTES, to.name)) {
                return { selector: '#' + from.params.slug };
            }
        }

        return { x: 0, y: 0 }
    }
})
