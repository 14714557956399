<template>
   <div class="swatches"
        :class="{ 'inListing': listing }"
        :style="{ 'padding-top': height }">
      <div :style="styles"></div>
   </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'Swatches',
        data() {
            return {
                block: [],
                height: this.size ? this.size : 30,
                direction: this.listing ? '180deg' : 'to right',
            }
        },
        props: {
            swatches: Array,
            size: Number,
            listing: Boolean,
        },
        computed: {
            styles() {
                let swatchCount = this.swatches.length; // 4 colors, for example
                let cellWidth = 100 / swatchCount; // 25 (% space) for example

                let swatchString = '';
                let count = 0;
                 _.forEach(this.swatches, function(hex) {
                     // where on the horizontal line this color starts
                     let start = hex + ' ' + count + '%, ';

                     // where on the horizontal line this color ends
                     count = count + cellWidth;
                     let end = hex + ' ' + count + '%, ';

                     // add to string. (example will be like: #ffffff 0%, #ffffff 5%, )
                     swatchString = swatchString + start + end;
                 });

                 // remove the trailing comma
                swatchString = _.trimEnd(swatchString, ', ');

                let styles = {
                    backgroundImage: 'linear-gradient(' + this.direction + ', ' + swatchString + ')',
                    backgroundSize: (swatchCount * this.height) + 'px',
                }

                if (! this.verbar) {
                    styles.height = this.height + 'px';
                }

                return styles;
            },
        }
    }
</script>

<style lang="scss">
   .swatches {
      display: block;

      * {
         display: block;
         background: repeat 0 0;
      }

      &:not(.inListing) {
         * {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
         }
      }

      &.inListing {
         * {
            height: 100% !important;
         }
      }
   }
</style>