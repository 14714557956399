<template>
   <div class="donate">
      <h3>$upport The $ite</h3>
      <slot>
         <p>If you like the content, help me pay for the server it lives on. Click the button below to
            make a donation.</p>
      </slot>
      <div class="mt-3">
         <Button text="Gimme Money" icon="play" :donate="true" />
      </div>
   </div>
</template>


<script>
    import Button from '@/components/Button';

    export default {
        name: 'DonateBox',
        components: {
            Button
        },
        data() {
            return {

            }
        },
        props: {
            text: String
        },
        computed: {
        }
    }
</script>