<template>
   <transition name="fade">
      <div id="loader" v-show="loading">
         <div class="container main-container">
            <Logo/>
            <p class="message">{{ message }}...</p>
            <Icon name="spinner" :spin="true"/>
         </div>
      </div>
   </transition>
</template>

<script>
    import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
    import Icon from 'vue-awesome/components/Icon';
    import Logo from '@/components/Logo.vue';

    export default {
        name: 'Loader',
        components: {
            Icon,
            Logo
        },
        data() {
            return {
                loading: this.show,
                message: 'Loading',
                messages: [
                    'Please hold while I connect you',
                    'Your call may be monitored',
                    'One sec, lemme get a pen',
                    'Turning on the machine',
                    'Cleaning up the mess',
                    'Cover your eyes, child',
                    'Untangling a knot',
                    'Generating a bright future',
                    'Slicing up the details',
                    'Loading the distractions'
                ]
            }
        },
        props: {
            show: Boolean,
        },
        watch: {
            show() {
                this.loading = this.show;
                let overlay = document.querySelector('#loader');
                if (this.show) {
                    disableBodyScroll(overlay);
                }
                else {
                    enableBodyScroll(overlay);
                }
            }
        },
        mounted() {
            this.message = this.messages[Math.floor( Math.random() * this.messages.length )];
        }
    }
</script>

<style scoped lang="scss">
   #loader {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9000;
      width: 100%;
      height: 100vh;
      background-color: $bg-color;
      text-align: center;

      .message {
         margin: 0 0 2rem;
         font-family: $font-family-serif;
         letter-spacing: 1.4px;
         font-size: 1.1rem;
         line-height: 1.4;
         text-transform: uppercase;
         font-weight: $font-weight-bold;
      }

      .fa-icon {
         width: 3rem;
         height: 3rem;
      }
   }

   .fade-enter-to, .fade-leave {
      transition-duration: 0.5s;
      transition-property: opacity;
      transition-timing-function: ease;
   }
   .fade-enter,
   .fade-leave-active {
      opacity: 0;
      pointer-events: none; /* disable user interaction */
      user-select: none; /* disable user selection */
   }
</style>