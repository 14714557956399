<template>
   <div class="tags">
      <ul>
         <li v-if="header" class="label"><span>{{ header }}:</span></li>
         <li v-for="(tag, key) in tags" :key="key" class="tag">
            <router-link :to="{ name: groupHandle, params: {group: groupHandle, slug: tag.slug} }">
               {{ tag.title }}
            </router-link>
         </li>
      </ul>
   </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'TagGroup',
        components: {
        },
        data() {
            return {
                groupHandle: _.toLower(this.group),
            }
        },
        props: {
            group: String,
            header: String,
            tags: Array
        },
    }
</script>


<style scoped lang="scss">
   $tagGutter: 3px;
   .tags {
      margin-bottom: 3px;

      ul {
         margin-left: -#{$tagGutter};
         margin-right: -#{$tagGutter};
      }
   }

   .tag, .label {
      display: inline-block;
      margin: 0 $tagGutter 7px;
      padding: 0 $tagGutter;

      * {
         display: block;
         padding: 5px 8px;
         border: 2px solid;
         vertical-align: middle;
         text-transform: uppercase;
         font-family: $font-family-serif;
         font-size: 0.9rem;
         font-weight: $font-weight-normal;
         letter-spacing: 1.3px;
         text-decoration: none;
         line-height: 1;
      }

      span {
         cursor: default;
         background-color: transparent;
         border-color: transparent;
         padding-left: 0;
         padding-right: 0;
         color: $black;
         font-weight: bold;
      }

      a {
         cursor: pointer;
         background-color: $tag-bg-color;
         border-color: $tag-border-color;
         color: $tag-text-color;
         transition: $transition;
      }
   }

   @include media-breakpoint-down(lg) {
      .label {
         display: block;
      }
   }
   @include media-breakpoint-up(lg) {
      .tag, .label {
         margin-bottom: 0;

         a {
            &:hover {
               background-color: $tag-bg-hover-color !important;
               border-color: $tag-border-hover-color;
               color: $tag-text-hover-color;
               transition: $transition;
            }
         }
      }
      .label {
         font-weight: $font-weight-bold;
      }
      .tag {
         * {
            padding: 3px 5px;
            font-size: 0.7rem;
         }
      }
   }
</style>