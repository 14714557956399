<template>
   <div v-html="entry.textarea"></div>
</template>

<script>
    export default {
        name: 'Impressum',
        data() {
            return {
                email: atob('ZW1vdGlvbmFsaXN0c0BnbWFpbC5jb20='),
            }
        },
        props: {
            entry: Object
        }
    }
</script>

<style scoped>
   >>> h2 {
      font-size: 1.5rem;
   }
</style>