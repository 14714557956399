<template>
   <div :class="getClasses">

      <router-link v-if="isRoute" :to="route">{{ text }}<Icon :name="iconName" /></router-link>

      <a v-else-if="isCustom" :href="attributes.href" :target="attributes.target">{{ text }}<Icon :name="iconName" /></a>

      <a v-else-if="donate" href="https://donorbox.org/emotionalists" class="custom-dbox-popup" target="_blank" rel="noreferrer">
         {{ text }}<Icon :name="iconName" />
      </a>

   </div>
</template>


<script>
    import _ from 'lodash';
    import Icon from 'vue-awesome/components/Icon';

    export default {
        name: 'Button',
        components: {
            Icon
        },
        data() {
            return {
                classes: ['btn', this.small ? 'btn-sm' : ''],
                iconName: this.icon ? this.icon : null,
            }
        },
        props: {
            route: Object,
            attributes: Object,
            text: String,
            small: Boolean,
            custom: Array,
            icon: String,
            donate: Boolean,
        },
        computed: {
            getClasses() {
                if (this.custom !== undefined && this.custom.length) {
                    this.custom.forEach(cls => {
                        // eslint-disable-next-line
                        this.classes.push(cls);
                    });
                }
                return this.classes;
            },
            isRoute() {
                return !_.isUndefined(this.route);
            },
            isCustom() {
                return !_.isUndefined(this.attributes) && this.attributes.href !== '';
            }
        }
    }
</script>

<style lang="scss">
   @import '~@/styles/_buttonLogic';

   $transition: all .13s;
   $border-width: 4px;

   // how big the "walls" are of the button (should be an even number)
   $depth: 18px;
   $depth-sm: 10px;

   // how far down the button goes when it's hovered over
   $reduceByWhenHover: 3px;
   $reduceByWhenHover-sm: 2px;

   // how far down the button goes when fully pressed
   $reduceByWhenActive: 10px;
   $reduceByWhenActive-sm: 5px;

   .btn, .cookie-consent-compliance {
      display: inline-block;
      position: relative;
      border: none;
      transition: $transition;
      margin: 0 0 $depth $depth;

      // button icon
      .fa-icon {
         position: relative;
         top: 1px;
         margin-left: 15px;
      }

      // the link inside the button
      > * {
         background: none;
         cursor: pointer;
         display: block;
         padding: 15px 30px;
         border-top: $border-width solid $black;
         border-right: $border-width solid $black;
         border-left: none;
         border-bottom: none;
         vertical-align: middle;

         color: $black;
         text-transform: uppercase;
         font-family: $font-family-serif;
         font-size: 1rem;
         font-weight: $font-weight-bold;
         letter-spacing: 2px;
         text-decoration: none;
         line-height: $base-line-height;
      }

      &.btn-sm {
         margin: 0 0 $depth-sm $depth-sm;
         * {
            padding: 10px 15px;
            font-size: 0.8rem;
            line-height: 1.3;
            letter-spacing: 4px;
         }
      }

      @include media-breakpoint-down(lg) {
         &.long-mobile-btn {
            > * {
               padding-left: 20px;
               padding-right: 20px;
               letter-spacing: 1px;
            }
         }
      }

      // desktop styling
      @include media-breakpoint-up(lg) {
         > * {
            letter-spacing: 5px;
         }
      }



      // making the button look 3D
      &::before, &::after {
         content: '';
         background: $black;
         border: none;
         position: absolute;
         transition: $transition;
      }

      // LEFT SIDE
      &::before {
         @include leftSide($depth, $reduceByWhenActive);
      }
      &.btn-sm::before {
         @include leftSide($depth-sm, $reduceByWhenActive-sm);
      }
      // BOTTOM SIDE
      &::after {
         @include bottomSide($depth, $reduceByWhenActive);
      }
      &.btn-sm::after {
         @include bottomSide($depth-sm, $reduceByWhenActive-sm);
      }

      // ======================================= BIG BUTTON ========================================

      // When the BIG button is hovered over
      @include media-breakpoint-up(lg) {
         &:hover {
            // this compensates the margin space around the button as its sides are contracting
            @include compensation($depth, $reduceByWhenHover);
            // LEFT SIDE
            &::before {
               @include leftSide($depth, $reduceByWhenHover, true);
            }
            // BOTTOM SIDE
            &::after {
               @include bottomSide($depth, $reduceByWhenHover, true);
            }
         }
      }

      // When the BIG button is fully pressed
      &:active {
         // this compensates the margin space around the button as its sides are contracting
         @include compensation($depth, $reduceByWhenActive);
         // LEFT SIDE
         &::before {
            @include leftSide($depth, $reduceByWhenActive, true);
         }
         // BOTTOM SIDE
         &::after {
            @include bottomSide($depth, $reduceByWhenActive, true);
         }
      }

      // ====================================== SMALL BUTTON =======================================

      // When the SMALL button is hovered over
      @include media-breakpoint-up(lg) {
         &.btn-sm:hover {
            // this compensates the margin space around the button as its sides are contracting
            @include compensation($depth-sm, $reduceByWhenHover-sm);
            // LEFT SIDE
            &::before {
               @include leftSide($depth-sm, $reduceByWhenHover-sm, true);
            }
            // BOTTOM SIDE
            &::after {
               @include bottomSide($depth-sm, $reduceByWhenHover-sm, true);
            }
         }
      }

      // When the SMALL button is pressed
      &.btn-sm:active {
         // this compensates the margin space around the button as its sides are contracting
         @include compensation($depth-sm, $reduceByWhenActive-sm);
         // LEFT SIDE
         &::before {
            @include leftSide($depth-sm, $reduceByWhenActive-sm, true);
         }
         // BOTTOM SIDE
         &::after {
            @include bottomSide($depth-sm, $reduceByWhenActive-sm, true);
         }
      }
   }
</style>