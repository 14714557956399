<template>
   <div class="social">
      <h2>Get Future Playlists</h2>
      <p>Follow me here to know when a new playlist is published.</p>
      <ul class="social">
         <li v-for="(link, key) in links" :key="key">
            <a :href="link.href" :title="link.title" target="_blank" rel=”noreferrer”>
               <Icon :scale="3" :name="link.icon" />
            </a>
         </li>
      </ul>
   </div>
</template>

<script>
    import Icon from 'vue-awesome/components/Icon';

    export default {
        name: 'SocialMedia',
        components: {
            Icon
        },
        props: {
            links: Object
        }
    }
</script>

<style scoped lang="scss">
   .social {

      >>> ul {
         list-style-type: none;
         padding: 0.5rem 0 0;
         margin: 0;
      }
      >>> li {
         display: inline;
         vertical-align: middle;
         margin: 0 1rem;
      }
      .fa-icon {
         color: $text-color;
      }

      @include media-breakpoint-up(lg) {
         .fa-icon {
            &:hover {
               color: $link-color;
            }
         }
      }
   }
</style>