// the object Vue Meta needs to insert meta tags into <head>
export function getMetaTags(title, url, image, description) {
    return {
        title: title,
        link: [{rel: 'canonical', href: url}],
        meta: [
            // standard
            {name: 'description', content: description},

            // Twitter
            {name: 'twitter:site', content: url},
            {name: 'twitter:title', content: title},
            {name: 'twitter:description', content: description},
            {name: 'twitter:image:src', content: image},

            // Facebook
            {property: 'og:title', content: title},
            {property: 'og:description', content: description},
            {property: 'og:image', content: image},
            {property: 'og:url', content: url},
        ],
    }
}