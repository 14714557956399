<template>
   <div v-if="pageIsReady" :class="{ 'navPush': addNavPush }">
      <PageNotFound :entry="entry" v-if="route === 'pageNotFound'"/>
      <Homepage :content="content" v-else-if="route === 'homepage'"/>
      <Search :content="content" :times="times" v-else-if="route === 'search'"/>
      <About :entry="entry" :randomPlaylist="randomPlaylist" :social="content.social" v-else-if="route === 'about'"/>
      <Impressum :entry="entry" v-else-if="route === 'impressum'"/>
      <div class="row" v-else>
         <div class="col-xs-12 col-md-8">
            <div v-html="entry.textarea"></div>
         </div>
      </div>
   </div>
</template>

<script>
    import _ from 'lodash';
    import {getMetaTags} from '@/plugins/functions.js';
    import Homepage from '@/views/singles/Homepage.vue';
    import Search from '@/views/singles/Search.vue';
    import About from '@/views/singles/About.vue';
    import Impressum from '@/views/singles/Impressum.vue';
    import PageNotFound from '@/views/singles/PageNotFound.vue';

    export default {
        name: 'Single',
        components: {
            Homepage,
            Search,
            About,
            Impressum,
            PageNotFound,
        },
        data() {
            return {
                singles: this.content.singles,
                randomPlaylist: _.sample(this.content.playlists),
                entry: {},
                route: this.$route.name,
            }
        },
        props: {
            content: Object,
            slug: String,
            editable: Boolean,
            times: Array,
        },
        computed: {
            pageIsReady() {
                return ! _.isEmpty(this.entry);
            },
            addNavPush() {
                if (this.$route.name === 'homepage') {
                    return false;
                }
                return true;
            },
        },
        methods: {
            getMetaTags,
            setEntry() {
                if (this.singles.length && _.isEmpty(this.entry)) {
                    this.singles.forEach((entry) => {
                        if (entry.slug === this.slug) {
                            this.entry = entry;
                        }
                    });
                }
            }
        },
        mounted() {
            this.setEntry();
        },
        metaInfo() {
            if (this.pageIsReady) {
               return this.getMetaTags(
                   this.entry.meta.title,
                   this.entry.meta.url,
                   this.entry.meta.image,
                   this.entry.meta.description
               );
            }

            return {};
        }
    }
</script>