<template>
   <span>{{ songCount }} songs <span v-if="entry.genres.length">in the genres <span v-for="(genre, key) in genres" :key="key"><router-link v-if="includeLinks" :to="{ name: 'genres', params: {group: 'genres', slug: genre.slug} }">{{ genre.title }}</router-link><span v-else>{{ genre.title }}</span>, </span>and more</span> / {{ entry.duration }}</span>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'Description',
        data() {
            return {
                songCount: this.entry.tracklist.length,
                genres: _.take(this.entry.genres, 3),
            }
        },
        props: {
            entry: Object,
            includeLinks: Boolean
        },
    }
</script>

<style lang="scss">
   .description {
      a {
         color: inherit;

         @include media-breakpoint-up(lg) {
            &:hover {
               color: $link-color;
            }
         }
      }
   }
</style>