<template>
   <div>
      <h1>{{ entry.title }}</h1>
      <div v-html="entry.textarea"></div>
   </div>
</template>

<script>
    export default {
        name: 'PageNotFound',
        props: {
            entry: Object,
        },
    }
</script>