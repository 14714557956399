<template>
   <div id="app">
      <Loader :show="! contentIsReady" />
      <div class="container main-container" :style="{ minHeight: viewportHeight}">

         <Logo />

         <div id="nav" class="links" :class="{ 'fixed': nav.isFixed }"
              :style="{ 'height': nav.height }">
            <ul ref="nav">
               <li id="linkToPlaylists">
                  <Icon name="backward" v-if="addBackArrow" class="rewind-sm" />
                  <Icon name="backward" v-if="addBackArrow" class="rewind-lg" />
                  <router-link :to="{name: 'homepage'}">All Playlists</router-link>
               </li>
               <li>
                  <router-link :to="{name: 'search'}">Search</router-link>
               </li>
               <li>
                  <router-link :to="{name: 'about'}">About</router-link>
               </li>
            </ul>
         </div>
         <router-view :content="content" :key="$route.path" v-if="contentIsReady" />
      </div>

      <div id="footer" ref="footer" v-show="showFooter">
         <div class="container">
            <div class="inner">
               <p class="copy">A <a href="https://modelesque.dev" target="_blank" rel="noopener noreferrer">Modelesque</a> website
                  built with <a href="https://craftcms.com/" target="_blank" rel="noopener noreferrer">Craft CMS</a>
                  and <a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer">Vue.js</a>.
                  <span>Copyright &copy; Emotionalists {{ currentYear }}.</span></p>
               <ul class="links">
                  <li><router-link :to="{name: 'about'}">About</router-link></li>
                  <li><router-link :to="{name: 'impressum'}">Impressum</router-link></li>
               </ul>
            </div>
         </div>
      </div>

      <cookie-consent buttonLabel="Accept">
         <template slot="message">
            <p>This site uses cookies to ensure you get the best experience.
               <a href="https://www.cookiesandyou.com/" target="_blank" rel="noopener noreferrer">Read more</a></p>
         </template>
         <template slot="button">
            <div class="btn btn-sm">
               <button type="button">Accept</button>
            </div>
         </template>
      </cookie-consent>
   </div>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';
    import CookieConsent from 'vue-cookieconsent-component';
    import Logo from '@/components/Logo.vue';
    import Loader from '@/components/Loader.vue';
    import Icon from 'vue-awesome/components/Icon';

    export default {
        name: 'baseLayout',
        components: {
            CookieConsent,
            Logo,
            Loader,
            Icon,
        },
        data() {
            return {
                playlistsLoaded: false,
                singlesLoaded: false,
                tagsLoaded: false,
                categoriesLoaded: false,
                contentIsReady: false,
                nav: {
                    positionFromTop: 0,
                    height: 'auto',
                    isFixed: false,
                    lastScrollPosition: 0,
                },
                viewportHeight: '0px',
                content: {
                    playlists: [],
                    singles: [],
                    genres: [],
                    moods: [],
                    colors: [],
                    activities: [],
                    filters: {
                        meta: {
                            genres: {},
                            moods: {},
                            colors: {},
                            activities: {},
                            times: {},
                        },
                        genres: [],
                        moods: [],
                        colors: [],
                        activities: [],
                        times: [
                            {
                                title: 'Morning',
                                slug: 'morning',
                                btnText: 'Morning',
                                definition: 'best played early in the morning.'
                            },
                            {
                                title: 'Daytime',
                                slug: 'day',
                                btnText: 'Day',
                                definition: 'best played while it\'s light outside.'
                            },
                            {
                                title: 'Nighttime',
                                slug: 'night',
                                btnText: 'Night',
                                definition: 'best played after the sun goes down.'
                            },
                            {
                                title: 'Late Night',
                                slug: 'late-night',
                                btnText: 'Late Night',
                                definition: 'best played after 2 a.m. before you\'ve gone to sleep.'
                            },
                        ],
                    },
                    social: {
                        fb: {
                            title: 'Facebook',
                            handle: 'emotionalists',
                            icon: 'brands/facebook-square',
                            href: 'https://www.facebook.com/emotionalists',
                        },
                        tw: {
                            title: 'Twitter',
                            handle: 'emotionalists_',
                            icon: 'brands/twitter',
                            href: 'https://twitter.com/emotionalists_',
                        },
                        ig: {
                            title: 'Instagram',
                            handle: '_emotionalists_',
                            icon: 'brands/instagram',
                            href: 'https://www.instagram.com/_emotionalists_',
                        },
                        sp: {
                            title: 'Spotify',
                            handle: 'ilud5i19z2y6j6u4e9whrtyph',
                            icon: 'brands/spotify',
                            href: 'spotify:user:ilud5i19z2y6j6u4e9whrtyph',
                        },
                        yt: {
                            title: 'YouTube',
                            handle: 'UC2OK3SyH_mm2SIB5DbGLI8w',
                            icon: 'brands/youtube',
                            href: 'https://www.youtube.com/channel/UC2OK3SyH_mm2SIB5DbGLI8w',
                        },
                        sc: {
                            title: 'SoundCloud',
                            handle: 'emotionalists',
                            icon: 'brands/soundcloud',
                            href: 'https://soundcloud.com/emotionalists',
                        },
                    },

                },
            }
        },
        computed: {
            currentYear() {
                let date = new Date();
                return date.getFullYear();
            },
            addBackArrow() {
                if (this.$route.name === 'homepage') {
                    return false;
                }
                return true;
            },
            showFooter() {
                if (this.$route.name === 'playlist') {
                    return false;
                }
                return true;
            },
        },
        watch: {
            playlistsLoaded() {
                this.checkContent();
            },
            singlesLoaded() {
                this.checkContent();
            },
            tagsLoaded() {
                this.checkContent();
            },
            categoriesLoaded() {
                this.checkContent();
            },
        },
        methods: {
            checkContent() {
                if (this.playlistsLoaded && this.singlesLoaded && this.tagsLoaded && this.categoriesLoaded) {
                    this.contentIsReady = true;
                }
                else {
                    this.contentIsReady = false;
                }
            },
            fixNavOnScroll() {
                // get the current scroll position
                let currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

                // determine whether we need to fix position the nav or not
                this.nav.isFixed = currentScrollPosition >= this.nav.positionFromTop;
            }
        },

        mounted() {
            // set the nav's height and position from top of viewport, then register the scroll event
            // to stick it to the top of the page
            this.nav.positionFromTop = this.$refs.nav.offsetTop;
            this.nav.height = this.$refs.nav.offsetHeight + 'px';
            window.addEventListener('scroll', this.fixNavOnScroll);

            // add min-height to main-container div so that the footer appears sticky for short pages
            this.viewportHeight = (window.innerHeight - this.$refs.footer.offsetHeight) + 'px';

            // keyboard shortcuts to go to different pages
            this.$mousetrap.bind('s', (event) => {
                event.preventDefault();
                if (this.$route.name === 'search') {
                    let input = document.getElementById('searchInput');
                    input.focus();
                }
                else {
                    this.$router.push({name: 'search', hash: '#field'});
                }
            });
            this.$mousetrap.bind('p', (event) => {
                event.preventDefault();
                if (this.$route.name !== 'homepage') {
                    this.$router.push({name: 'homepage'});
                }
            });
            this.$mousetrap.bind('a', (event) => {
                event.preventDefault();
                if (this.$route.name !== 'about') {
                    this.$router.push({name: 'about'});
                }
            });
            this.$mousetrap.bind('k', (event) => {
                event.preventDefault();
                if (this.$route.name !== 'about') {
                    this.$router.push({name: 'about', hash: '#shortcuts'});
                }
            });
            this.$mousetrap.bind('backspace', (event) => {
                event.preventDefault();
                this.$router.go(-1);
            });
        },


        created() {
            // grab all the JSON from Craft
            let params = {};
            let _this = this;

            // get playlists
            axios.get(process.env.VUE_APP_URL + '/api/playlists.json', params)
                .then(function(response) {
                    _this.content.playlists = response.data.data;
                    _this.playlistsLoaded = true;
                });

            // get singles
            axios.get(process.env.VUE_APP_URL + '/api/singles.json', params)
                .then(function(response) {
                    _this.content.singles = response.data.data;
                    _this.singlesLoaded = true;
                });

            // get tags and reorganize them
            axios.get(process.env.VUE_APP_URL + '/api/tags.json', params)
                .then(function(response) {
                    let tags = response.data.data;
                    let meta = response.data.meta;
                    let genres = []
                        , moods = []
                        , colors = [];
                    _.forEach(tags, function(tag) {
                        switch (tag.groupId) {
                            case '1':
                                genres.push(tag);
                                break;
                            case '2':
                                moods.push(tag);
                                break;
                            case '3':
                                colors.push(tag);
                                break;
                        }
                    });
                    _this.content.filters['genres'] = _.sortBy(genres, 'title');
                    _this.content.filters['moods'] = _.sortBy(moods, 'title');
                    _this.content.filters['colors'] = _.sortBy(colors, 'title');

                    _this.content.filters.meta['genres'] = meta.genres;
                    _this.content.filters.meta['moods'] = meta.moods;
                    _this.content.filters.meta['colors'] = meta.colors;

                    _this.tagsLoaded = true;
                });

            // get categories and reorganize them
            axios.get(process.env.VUE_APP_URL + '/api/categories.json', params)
                .then(function(response) {
                    let categories = response.data.data;
                    let meta = response.data.meta;
                    let activities = [];
                    _.forEach(categories, function(category) {
                        switch (category.groupId) {
                            case '1':
                                activities.push(category);
                                break;
                        }
                    });
                    _this.content.filters['activities'] = _.sortBy(activities, 'title');
                    _this.content.filters.meta['activities'] = meta.activities;
                    _this.content.filters.meta['times'] = meta.times;
                    _this.categoriesLoaded = true;
                });
        },
    }
</script>

<style lang="scss">
   // the fade-in effect when switching from page to page
   .fade-enter-active,
   .fade-leave-active {
      transition-duration: 0.3s;
      transition-property: opacity;
      transition-timing-function: ease;
   }

   .fade-enter,
   .fade-leave-active {
      opacity: 0
   }
</style>
