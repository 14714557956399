<template>
   <div v-if="pageIsReady">
      <EntryList :entries="content.playlists" :social="content.social" />
   </div>
</template>

<script>
    import EntryList from '@/components/playlists/EntryList.vue';
    import _ from 'lodash';

    export default {
        name: 'Homepage',
        components: {
            EntryList
        },
        data() {
            return {

            }
        },
        props: {
            content: Object,
        },
        computed: {
            pageIsReady() {
                if (this.content.playlists.length) {
                    return true;
                }
                return false;
            }
        },
    }
</script>