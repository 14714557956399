<template>
   <ul class="entryList" v-if="listIsReady">
      <li v-for="(entry, index) in entriesWithAds"
          :key="index"
          :class="[entry.type]"
          :id="setId(entry, index)"
          :ref="entry.type === 'playlist' ? 'playlists' : 'ctas'"
          :data-position="index">

         <div v-if="entry.type === 'playlist'">
            <Swatches v-if="entry.swatches.length" :listing="true" :swatches="entry.swatches" />

            <div>
               <h2>{{ entry.title }}</h2>
               <p>
                  <Description class="description" :entry="entry" :includeLinks="true" />
               </p>

               <TagsGroup v-if="entry.moods.length" header="Feels" :tags="entry.moods"
                          group="moods" />

               <div class="mt-2">
                  <Button text="Listen"
                          :small="true"
                          :route="{ name: 'playlist', params: {slug: entry.slug, entry: entry} }" />
               </div>
            </div>
         </div>
         <div v-else>
            <SocialMedia v-if="entry.component === 'social'" :links="social" />
            <DonateBox v-else-if="entry.component === 'donate'" />
         </div>
      </li>
   </ul>
</template>


<script>
    import _ from 'lodash';
    import TagsGroup from '@/components/playlists/TagsGroup.vue';
    import Description from '@/components/playlists/Description.vue';
    import Swatches from '@/components/playlists/Swatches.vue';
    import DonateBox from '@/components/ctas/DonateBox.vue';
    import SocialMedia from '@/components/ctas/SocialMedia.vue';
    import Button from '@/components/Button.vue';

    export default {
        name: 'EntryList',
        components: {
            TagsGroup,
            Description,
            Button,
            Swatches,
            SocialMedia,
            DonateBox,
        },
        data() {
            return {
                activePlaylistKey: null,
                ctaInterval: 8,
                canRunAds: this.$canRunAds,
                ctas: [
                    {type: 'cta', slug: 'social', component: 'social'},
                    {type: 'cta', slug: 'donate', component: 'donate'},
                ],
                entriesWithAds: [],
                listIsReady: false,
            }
        },
        props: {
            entries: Array,
            social: Object,
        },
        computed: {
            genres() {
                if (this.entry.genres.length) {
                    return this.entry.genres.slice(0, 3);
                }
                return [];
            },
        },
        methods: {
            setId(entry, index) {
                if (entry.type === 'playlist') {
                    return entry.slug;
                }
                return entry.slug + '-' + index;
            },

            // create the listing of entries the user will see
            setEntries() {
                let ctaIndex = 0;
                let entryCount = this.entries.length;
                if (this.entries.length > (this.ctaInterval * 2)) {
                    entryCount = this.entries.length - this.ctaInterval;
                }

                _.forEach(this.entries, (entry, index) => {
                    /* CTAs
                        - This inserts a CTA block every {ctaInterval} number of playlists in the list.
                        However, it does this in a range of the {ctaInterval} and total number of
                        playlist entries minus the {ctaInterval}. So for example, if the {ctaInterval}
                        is 5 and there are 30 playlist entries, this inserts a CTA after every 5th
                        playlist but doesn't start until 5 playlists are listed first and stops after
                        the 25th playlist is listed. This allows me to always force a CTA at the end
                        of list and not have it be close to its previous CTA.
                    */
                    if (_.inRange(index, this.ctaInterval, entryCount) && index % this.ctaInterval === 0) {
                        if (ctaIndex >= this.ctas.length) {
                            ctaIndex = 0;
                        }
                        this.entriesWithAds.push(this.ctas[ctaIndex]);
                        ++ctaIndex;
                    }
                    this.entriesWithAds.push(entry);
                });

                // force a final CTA at the end of the list
                if (ctaIndex >= this.ctas.length) {
                    ctaIndex = 0;
                }

                this.entriesWithAds.push(this.ctas[ctaIndex]);

                // the list of entries and CTAs is ready to display
                this.listIsReady = true;
            },

            // set the {activePlaylistKey} to the playlist you were just in
            setActivePlaylistKey() {
                if (!_.isUndefined(this.$route.params.scrollToSlug && _.isString(this.$route.params.scrollToSlug))) {
                    let key = _.findKey(this.entries, {'slug': this.$route.params.scrollToSlug});
                    if (!_.isUndefined(key)) {
                        this.activePlaylistKey = key;
                    }
                }
            },

            // move to a specific playlist in the $refs.playlists array
            scrollToPlaylist(key) {
                let playlists = this.$refs.playlists;
                let top = 0;

                // if you try to go up from the first playlist, reset to null and go to top of page
                if (key < 0) {
                    key = null;
                }

                // if you go past the last playlist, reset to null and go to top of page
                if (key >= playlists.length) {
                    key = null;
                }

                // if no playlist exists, go to top of page, otherwise to top of selected playlist
                if (_.isUndefined(playlists[key])) {
                    key = null;
                    top = 0;
                }
                else {
                    top = playlists[key].offsetTop;
                }

                // perform the page movement
                window.scrollTo({
                    left: 0,
                    top: top,
                    behavior: 'auto'
                });

                // set the key for future use
                return this.activePlaylistKey = key;
            },

            /*
               Moving to next or previous playlist in a list:

               If {activePlaylistKey} is null, then it's because of mouse movement or just the
               default setting. In that case, loop through and find the nearest playlist from where
               you are on the page and the direction you want to move to.
             */
            goToNextPlaylist() {
                if (_.isNull(this.activePlaylistKey)) {
                    let playlists = this.$refs.playlists;
                    for (let i = 0; i < playlists.length; i++) {
                        if (playlists[i].offsetTop > window.scrollY) {
                            return this.scrollToPlaylist(i);
                        }
                    }
                }

                return this.scrollToPlaylist(parseInt(this.activePlaylistKey) + 1);
            },
            goToPrevPlaylist() {
                if (_.isNull(this.activePlaylistKey)) {
                    let playlists = this.$refs.playlists;
                    for (let i = 0; i < playlists.length; i++) {
                        if (playlists[i].offsetTop >= window.scrollY) {
                            return this.scrollToPlaylist(i - 1);
                        }
                    }
                }

                return this.scrollToPlaylist(parseInt(this.activePlaylistKey) - 1);
            },

            // go into an entry that's at the top of the page when navigating with the keyboard
            goToEntry() {
                if (this.activePlaylistKey !== null) {
                    this.$router.push({
                        name: 'playlist',
                        params: {
                            slug: this.$refs.playlists[this.activePlaylistKey].id
                        }
                    });
                }
            }
        },
        mounted() {

            // trigger the creation of the listing of entries the user will see
            this.setEntries();

            // if coming from a playlist entry, set the {activePlaylistKey} to that entry
            this.setActivePlaylistKey();

            // set the keyboard commands applicable to this page
            this.$mousetrap.bind('down', (event) => {
                event.preventDefault();
                this.goToNextPlaylist();
            });
            this.$mousetrap.bind('up', (event) => {
                event.preventDefault();
                this.goToPrevPlaylist();
            });
            this.$mousetrap.bind(['space', 'right'], (event) => {
                event.preventDefault();
                this.goToEntry();
            });

            // if the user moves their mouse, force the {activePlaylistKey} to be null, forcing it
            // to be reevaluated next time they use their keyboard to navigate the listing
            document.addEventListener('mousemove', () => {
                this.activePlaylistKey = null;
            });
        },

        // remove these keyboard commands so they don't trigger on other pages
        beforeDestroy() {
            this.$mousetrap.unbind('down');
            this.$mousetrap.unbind('up');
            this.$mousetrap.unbind('space');
            this.$mousetrap.unbind('right');
        }
    }
</script>

<style scoped lang="scss">
   ul {
      list-style-type: none;
      padding: 0;
   }

   li {
      > div {
         padding: $space-under-nav-lg 0;
      }

      &.playlist {
         border-top: $hr-border;
         > div {
            display: flex;
         }
      }

      &:first-child {
         border-top: none;
      }

      &.cta, &.ad {
         border-top: $hr-border;
         padding: $space-under-nav-lg 0;
         &:last-child {
            padding-bottom: 0;
         }
         > div {
            background-color: $box-bg-color;
            padding: 2rem 1.5rem;
         }
      }

      &:last-child {
         border-bottom: none;
         > div {
            margin-bottom: 0;
         }
      }
   }

   h2 {
      cursor: default;
      margin: 0 0 1rem;
      letter-spacing: 2px;
      line-height: 1;
      vertical-align: top;
      text-transform: none;
   }

   .swatches {
      width: 50px;
      position: relative;
      margin-right: 1rem;
   }
</style>
