<template>
   <div class="about">
      <div class="row">
         <div class="col-lg-5">
            <h1 class="header" v-html="header"></h1>
            <Icon class="large-arrow" :scale="10" name="long-arrow-alt-right"/>
         </div>

         <div class="col-lg-7">
            <div class="story" v-html="entry.textarea"></div>

            <div class="mt-3">
               <Button text="Try a random playlist" icon="play" :custom="['long-mobile-btn']" :route="playlistLink" />
            </div>
         </div>
      </div>

      <div id="social-media" class="cta">
         <SocialMedia :links="social" />
      </div>

      <div class="row">
         <div id="shortcuts" class="col-lg shortcuts">
            <h3>Keyboard Shortcuts</h3>
            <p>You can navigate the site easier with these shortcuts. Just hit the key on the your
            keyboard!</p>

            <h4>Pages</h4>
            <ul>
               <li><span>p</span> view all playlists</li>
               <li><span>s</span> go to the search page and start typing</li>
               <li><span>a</span> go to about page</li>
               <li><span>backspace</span> go back to previous page</li>
            </ul>

            <h4>Perusing Playlists</h4>
            <ul>
               <li><span>&#x25BC;</span> skip to next playlist in list</li>
               <li><span>&#9650;</span> go back to previous playlist in list</li>
               <li><span>&#9658;</span> or <span>spacebar</span> view current playlist</li>
               <li><span>&#9668;</span> go back to all playlists (when viewing an entry)</li>
            </ul>
         </div>

         <div class="col-lg" id="donate">
            <DonateBox>
               <p>If you like the content, help me pay for the server it lives on. Click the button below to
                  make a donation. As the old Wellerism goes: "Every little bit counts," said the woman whose
                  husband was drowning as she pissed in the sea!</p>
            </DonateBox>
         </div>
      </div>
   </div>
</template>

<script>
    import _ from 'lodash';
    import SocialMedia from '@/components/ctas/SocialMedia.vue';
    import DonateBox from '@/components/ctas/DonateBox.vue';
    import Button from '@/components/Button.vue';
    import Icon from 'vue-awesome/components/Icon';

    export default {
        name: 'About',
        components: {
            SocialMedia,
            DonateBox,
            Icon,
            Button
        },
        data() {
            return {

            }
        },
        props: {
            entry: Object,
            randomPlaylist: Object,
            social: Object,
        },
        computed: {
            header() {
                let words = _.split(this.entry.header, '|');
                for (let i = 0; i < words.length; i++) {
                    words[i] = '<span class="line' + (i + 1) + '">' + _.trim(words[i]) + '</span>';
                }
                return _.join(words, ' ');
            },
            playlistLink() {
                return { name: 'playlist', params: {slug: this.randomPlaylist.slug, entry: this.randomPlaylist} }
            },
        }
    }
</script>

<style scoped lang="scss">
   .header {
      font-size: 3rem;
      margin-bottom: 2rem;
   }
   .large-arrow {
      display: none;
   }

   @include media-breakpoint-up(lg) {
      .header {
         margin-bottom: 0.5rem;
         >>> span {
            display: block;
            font-size: 3.5rem;
            line-height: 1;
            /*text-align: right;*/

            // This
            &.line1 {
               font-size: 7rem;
               line-height: 0.8;
               letter-spacing: 15px;
            }

            // is a story
            &.line2 {
               font-size: 4.5rem;
               letter-spacing: 2px;
            }

            // about you
            &.line3 {
               letter-spacing: 8px;
            }

            // and me
            &.line4 {
               letter-spacing: 8px;
            }
         }
      }
      .large-arrow {
         display: block;
      }
   }

   .story {
      >>> h3 {
         background-color: $black;
         display: inline-block;
         padding: 0px 13px 5px;
         font-size: 2rem;
         line-height: 1;
         letter-spacing: 3px;
         text-transform: lowercase;
         color: $bg-color;
         font-weight: $font-weight-normal;
      }
   }

   .fa-icon {
      color: $link-color;
   }

   >>> ol ul {
      list-style-type: disc;
      padding-left: 2rem;
   }

   >>> .cta {
      margin: 3rem 0;
      text-align: center;
      @include media-breakpoint-up(lg) {
         margin: 6rem 0;
      }
   }


   #shortcuts {
      display: none;
      @include media-breakpoint-up(xl) {
         display: block;
      }
   }

   .shortcuts {
      h4 {
         font-size: 1rem;
         letter-spacing: 1px;
         margin-top: 1rem;
      }

      ul {
         margin: 0;
         padding: 0;
         list-style-type: none;
      }

      li {
         font-size: 0.8rem;
         margin-bottom: 7px;
      }

      span {
         cursor: default;
         background-color: $box-bg-color;
         display: inline-block;
         padding: 5px 10px 7px;
         margin-right: 6px;
         border-radius: 4px;
         vertical-align: middle;
         font-weight: $font-weight-bold;
         line-height: 1;
         color: $black;
      }
   }

</style>