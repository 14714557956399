import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import Meta from 'vue-meta';
import VueMousetrap from 'vue-mousetrap';

// since I'm not using a lot of icons, just load them individually based on their name
import 'vue-awesome/icons/brands/facebook-square';
import 'vue-awesome/icons/brands/twitter';
import 'vue-awesome/icons/brands/instagram';
import 'vue-awesome/icons/brands/youtube';
import 'vue-awesome/icons/brands/soundcloud';
import 'vue-awesome/icons/brands/spotify';
import 'vue-awesome/icons/play';
import 'vue-awesome/icons/spinner';
import 'vue-awesome/icons/backward';
import 'vue-awesome/icons/long-arrow-alt-right';

// global css
import '@/assets/_global.scss';

Vue.component('v-style', {
    render: function (createElement) {
        return createElement('style', this.$slots.default);
    }
});

Vue.config.productionTip = false;
Vue.use(VueMousetrap);
Vue.use(Meta);

// Define global variables
Vue.prototype.$siteName = 'EMOTIONALISTS';
Vue.prototype.$description = 'Human-curated playlists of music, each consisting of 10-15 songs' +
    ' to accommodate specific feelings.';

// check to see if any ad blocker has blocked our ./adsbygoogle.js file from loading so
// we know if we should show ads or a donate CTA
// Vue.prototype.$canRunAds = window.ads === true ? true : false;
Vue.prototype.$canRunAds = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
